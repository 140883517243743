import React from "react";
import { useState, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";

// Firestore Database
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// CSS
import "./css/Home.css";
import SendingPage from "./SendingPage";

// Auth Stuff
import { UserAuth } from "../Context/AuthContext";

// Components
import NavBar from "../Components/NavBar";
import ScrapedResultsList from "../Components/ScrapedResultsList";

// import icons
import dashboard_icon from "../images/dashboard.png";
import scrape_icon from "../images/scrape.png";
import send_icon from "../images/send.png";
import logo_icon from "../images/logo.png";

const Home = () => {
  // _____________________________________ Auth (Nick) _________________________________
  const { user, logout } = UserAuth();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };
  // for jame's push

  // _____________________________________ Home Components (James) _________________________________
  // const [active, setActive] = useState("Send");
  const [active, setActive] = useState("Dashboard");
  const [scrapedData, setScrapedData] = useState([]);
  const [scrapedDatasetNames, setScrapedDatasetNames] = useState([]);
  const [datasets_sent, setDatasetsSent] = useState([]);

  useEffect(() => {
    if (user && user.uid) {
      const fetchScrapedData = async () => {
        console.log(user.uid);
        try {
          const response = await fetch(
            `https://api.thenodes.ca/get-user-scraped-results?userID=${user.uid}`,
            // `http://127.0.0.1:8000/get-user-scraped-results?userID=${user.uid}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          console.log(data);

          if (data.success) {
            setScrapedData(data.data);
            const dataMap = new Map(Object.entries(data.data));
            const dataset_names = Array.from(dataMap.keys());
            setScrapedDatasetNames(dataset_names);
          } else {
            console.log("Error fetching scraped data");
          }
        } catch (error) {
          console.log(error);
        }
      };

      // fetch datasets sent
      const fetchDatasetsSent = async () => {
        try {
          const response = await fetch(
            // `http://127.0.0.1:8000/get-user-sent-datasets?userID=${user.uid}`,
            // `https://api.thenodes.ca/get-user-sent-datasets?userID=${user.uid}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          console.log(data);

          if (data.success) {
            setDatasetsSent(data.data);
          } else {
            console.log("Error fetching datasets sent");
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchScrapedData();
      fetchDatasetsSent();
    }
  }, [user]);

  const handleAuthorization = async (userID) => {
    try {
      const response = await fetch(
        `https://api.thenodes.ca/check-authorized?user_id=${encodeURIComponent(
          userID
        )}`
      );
      // const response = await fetch(
      //   `http://127.0.0.1:8000/check-authorized?user_id=${encodeURIComponent(
      //     userID
      //   )}`
      // );

      const data = await response.json();
      console.log("User Authorized: ", data);

      if (data.authorized) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const MenuButton = ({ icon, name }) => {
    const handleButtonClick = async (e) => {
      e.preventDefault();
      if (name === "Send") {
        // 1. check authorized
        if (user.uid) {
          const userID = user.uid;
          // 2. if not authorized, get authorized
          const user_authorized = await handleAuthorization(userID);

          if (!user_authorized) {
            // 3. if not authorized, redirect to authorization page
            navigate("/authorization", {
              state: { scrapedData, scrapedDatasetNames },
            });
          } else {
            // 4. if authorized, set active to "Send"
            setActive(name);
          }
        }
      } else {
        setActive(name);
      }
    };
    return (
      <button className="MenuButton" title={name} onClick={handleButtonClick}>
        <img src={icon} alt="icon" />
        {/* <h3>{name}</h3> */}
      </button>
    );
  };

  const redirectHome = () => {
    navigate("/home");
  };

  const toggleDropdown = () => {
    const dropdown = document.getElementById("profile-dropdown");
    if (dropdown.style.display === "none" || dropdown.style.display === "") {
      dropdown.style.display = "block";
    } else {
      dropdown.style.display = "none";
    }
  };

  // __________________________________________________________________________

  return (
    <section id="Home">
      <div className="top">
        <img src={logo_icon} id="home-logo" onClick={redirectHome}></img>

        <div id="profile-icon" onClick={toggleDropdown}>
          <img src={logo_icon}></img>

          <div id="profile-dropdown">
            <button onClick={handleLogout} id="logout-btn">
              Logout
            </button>
          </div>
        </div>
      </div>

      <div className="content">
        {/* replace side menu with a component */}
        <div className="menu">
          <MenuButton icon={dashboard_icon} name="Dashboard" />
          <MenuButton icon={scrape_icon} name="Scrape" />
          <MenuButton icon={send_icon} name="Send" />
        </div>
        <div className="container">
          {active === "Dashboard" && (
            <ScrapedResultsList scrapedData={scrapedData} />
          )}
          {/* scrapedData, scrapedDatasetNames, currentDatasetSelected */}
          {active === "Scrape" && <div>Scrape</div>}
          {active === "Send" && (
            <SendingPage
              scrapedData={scrapedData}
              datasets_sent={datasets_sent}
              scrapedDatasetNames={["None", ...scrapedDatasetNames]}
            />
          )}
        </div>
      </div>

      {/* <NavBar></NavBar>
      <h1>Welcome {user && user.name}</h1>
      <p>User email: {user && user.email}</p>
      
      <button onClick={handleLogout}>Logout</button> */}
    </section>
  ); // comment
};

export default Home;

// if (name === "Send" && user && user.uid) {
//   let currentDatasetSelected = null;
//   navigate("/authorization", {
//     state: {
//       scrapedData,
//       scrapedDatasetNames,
//       currentDatasetSelected,
//     },
//   });
// navigate("/send-emails", {
//   state: { scrapedData, scrapedDatasetNames, currentDatasetSelected },
// });
// }
