import React from "react";
import NavBar from "../Components/NavBar";
import ImageCarousel from "../Components/LandingPageImageCarousel"; // Import the carousel
import "./css/Landing.css"; // Import the Landing page CSS

const Landing = () => {
  return (
    <section id="Landing">
      <NavBar />
      {/* Apply gradient and larger size to h1 */}
      <h1 className="landing-title text-gradient">
        Unleash your Networking Potential
      </h1>
      <ImageCarousel /> {/* Add the ImageCarousel component here */}
    </section>
  );
};

export default Landing;
