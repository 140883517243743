import "./css/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <section id="privacy-policy">
      <div className="container">
        <h1 className="title">Privacy Policy</h1>
        <h2 className="sub-title">Effective Date: September 10, 2024</h2>
        <p>
          Nodes ("we", "us", or "our") respects your privacy and is committed to
          protecting the personal information that you share with us. This
          Privacy Policy explains how we collect, use, and safeguard your
          information when you use the Nodes Chrome extension.
        </p>

        <h3 className="section-title">1. Information We Collect</h3>
        <p>
          When you use the Nodes extension, we may collect the following
          information:
        </p>
        <ul className="info-list">
          <li>
            <strong>LinkedIn Profile Data:</strong> We collect publicly
            available information from LinkedIn profiles based on your search
            filters, including names, bios, profile images, and profile links.
          </li>
          <li>
            <strong>Account Information:</strong> If you create an account or
            log in through the Nodes extension, we collect your login
            credentials and any information you provide to us, such as your
            username and email address.
          </li>
        </ul>

        <h3 className="section-title">2. How We Use the Information</h3>
        <p>The information we collect is used solely for the purpose of:</p>
        <ul className="info-list">
          <li>
            Automating the sourcing of LinkedIn profiles based on your search
            filters.
          </li>
          <li>
            Organizing and saving the collected profile information to your
            Nodes account.
          </li>
          <li>Generating email format suggestions for potential outreach.</li>
          <li>
            Improving the functionality and user experience of the Nodes
            extension.
          </li>
        </ul>
        <p>
          We do not sell, rent, or trade any personal information to third
          parties.
        </p>

        <h3 className="section-title">3. Data Storage and Security</h3>
        <p>
          We take your privacy seriously and employ security measures to protect
          your personal data. All information collected is stored securely and
          is accessible only by authorized personnel. However, please note that
          no method of transmission over the Internet is 100% secure, and we
          cannot guarantee absolute security.
        </p>

        <h3 className="section-title">4. Third-Party Services</h3>
        <p>
          Nodes uses LinkedIn's publicly available information, but it is not
          affiliated with LinkedIn in any way. We encourage users to respect
          LinkedIn's terms of service and privacy policies when using the
          extension.
        </p>

        <h3 className="section-title">5. Your Choices</h3>
        <p>
          You can stop the collection of information by the Nodes extension by
          uninstalling the extension from your browser.
        </p>

        <h3 className="section-title">6. Changes to This Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted here with an updated effective date.
        </p>

        <h3 className="section-title">7. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
          <a href="mailto:ansapplication@gmail.com" className="contact-link">
            {" "}
            ansapplication@gmail.com
          </a>
        </p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
