import React from "react";
import "./css/SendEmails.css";
import sendImage from "../images/send.png";

const SendEmails = ({
  setMessage,
  setSubject,
  subject,
  message,
  handleSendEmails, // get handleSendEmails as a prop from parent
}) => {
  // Function to handle message change in the textarea
  const handleMessageChange = (event) => {
    setMessage(event.target.value); // Update message state in SendingPage
  };

  // Function to handle subject change in the input
  const handleSubjectChange = (event) => {
    setSubject(event.target.value); // Update subject state in SendingPage
  };

  return (
    <div className="send-emails-container">
      {/* Subject input */}
      <input
        type="text"
        value={subject}
        onChange={handleSubjectChange}
        placeholder="subject:"
        className="subject"
      />

      {/* Message textarea */}
      <textarea
        value={message}
        onChange={handleMessageChange}
        placeholder="message:"
        className="email-area"
      />

      {/* Use the big send button for sending emails */}
      <button className="send-btn" onClick={handleSendEmails}>
        <img src={sendImage} alt="send" />
      </button>
    </div>
  );
};

export default SendEmails;
