import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../Context/AuthContext";
import "./css/SignUp.css"; // Import the shared CSS file
import logo from "../images/logo_with_nodes.png"; // Adjust the logo import based on your logo file's name and path
import TermsOfService from "../Components/TermsOfService"; // Import the TermsOfService component

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false); // State for Terms acceptance
  const [showTerms, setShowTerms] = useState(false); // State to handle modal visibility
  const { createUser } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if terms are accepted before proceeding
    if (!termsAccepted) {
      setError("You must agree to the Terms of Service before signing up.");
      return;
    }

    const success = await createUser(email, password);
    if (success === true) {
      navigate("/home");
    } else {
      setError(success);
    }
  };

  return (
    <div className="auth-container">
      {/* Logo at the top */}
      <img src={logo} alt="Logo" className="logo" />
      <div className="auth-card">
        <div>
          <h1 className="auth-title">Sign Up</h1>
          <p className="auth-subtitle">
            Already have an account? <Link to="/authentication">Sign in.</Link>
          </p>
        </div>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="auth-form">
          <div>
            <label className="auth-label">Email Address</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              className="auth-input"
              placeholder="Enter your email"
              required
            />
          </div>
          <div>
            <label className="auth-label">Password</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              className="auth-input"
              placeholder="Enter your password"
              required
            />
          </div>

          {/* Terms of Service Checkbox */}
          <div className="terms-container">
            {/* Checkbox for accepting Terms of Service */}
            <input
              type="checkbox"
              id="terms-checkbox"
              onChange={(e) => setTermsAccepted(e.target.checked)}
              required
            />
            {/* Label for the checkbox */}
            <label htmlFor="terms-checkbox" className="terms-label">
              I agree to the
            </label>

            {/* Separate link for viewing Terms of Service, outside of the label */}
            <span className="terms-link" onClick={() => setShowTerms(true)}>
              Terms of Service
            </span>
          </div>

          <button type="submit" className="auth-button">
            Sign Up
          </button>
        </form>
      </div>

      {/* Privacy Policy link */}
      <div className="privacy-link">
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </div>

      {/* Terms of Service Modal */}
      {showTerms && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button
              className="close-button"
              onClick={() => setShowTerms(false)}
            >
              &times;
            </button>
            <TermsOfService />
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
