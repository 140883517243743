import React, { useState, useEffect } from "react";
import Dropdown from "../Components/SelectedDataDropdown";
import { UserAuth } from "../Context/AuthContext";
import SendEmails from "./SendEmails";
import "./css/SendingPage.css";

import defaultImg from "../images/default-profile.png";

const ResultEntry = ({ name, company, profile_link, profile_img }) => {
  return (
    <div className="result-entry">
      <a
        className="result-info name"
        title={name}
        href={profile_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="profile-img"
          src={profile_img ? profile_img : defaultImg}
          alt="profile"
        ></img>
        <div>{name}</div>
      </a>
      <div className="result-info" id="company" title={company}>
        {company}
      </div>
    </div>
  );
};

const SendingPage = ({ scrapedData, datasets_sent, scrapedDatasetNames }) => {
  const { user } = UserAuth();
  const [selectedOption, setSelectedOption] = useState("None");
  const [emailData, setEmailData] = useState([]);
  const [emailsSentSuccessfully, setEmailsSentSuccessfully] = useState(false);
  const [alreadySentWarning, setAlreadySentWarning] = useState(false);

  // Keep the message and subject states in SendingPage
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const handleDropdownChange = (e) => {
    e.preventDefault();
    const datasetName = e.target.value;
    setSelectedOption(datasetName);

    console.log(datasets_sent);
    // Check if the dataset has already been sent
    for (let i = 0; i < datasets_sent.length; i++) {
      console.log(datasets_sent[i], datasetName);
      if (datasets_sent[i] === datasetName) {
        setAlreadySentWarning(true);
        break;
      } else {
        setAlreadySentWarning(false);
      }
    }

    const selectedDataset = scrapedData[datasetName];

    if (selectedDataset) {
      setEmailData(selectedDataset);
    } else {
      setEmailData([]);
    }
  };

  useEffect(() => {
    if (selectedOption && scrapedData) {
      const selectedDataset = scrapedData[selectedOption];
      if (selectedDataset) {
        setEmailData(selectedDataset);
      }
    }
  }, [selectedOption, scrapedData]);

  const handleSendEmails = async () => {
    const userEmailDetails = {
      userID: user.uid,
      emailData: emailData.map(
        ({
          name,
          company,
          email_formats,
          date_added,
          profile_image,
          profile_link,
        }) => ({
          name,
          company,
          email_formats,
          date_added,
          profile_image,
          profile_link,
        })
      ),
      message, // use message state here
      subject, // use subject state here
      datasetName: selectedOption,
    };

    console.log(userEmailDetails);

    try {
      const response = await fetch("https://api.thenodes.ca/send-emails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userEmailDetails),
      });
      const data = await response.json();
      console.log(data);

      if (data.status === "success") {
        setEmailsSentSuccessfully(true);
      }
    } catch (e) {
      console.log(e);
    }
    return;
  };

  return (
    <div id="sending-page">
      <Dropdown
        data={scrapedDatasetNames}
        currentDatasetSelected={selectedOption}
        handleDropdownChange={handleDropdownChange}
      />

      {alreadySentWarning ? (
        <p>You have already sent to this dataset!</p>
      ) : null}

      <div className="send-emails-portion-container">
        {/* Pass setMessage, setSubject, and handleSendEmails as props to the child component */}
        <SendEmails
          setMessage={setMessage}
          setSubject={setSubject}
          subject={subject}
          message={message}
          handleSendEmails={handleSendEmails} // pass the function to the child component
        />

        <div className="recipient-container">
          <div className="selected-recipients-list">
            <h3>Selected Recipients:</h3>
            {emailData && emailData.length > 0 ? (
              emailData.map((email, index) => (
                <div key={index}>
                  <ResultEntry
                    key={index}
                    name={email.name}
                    company={`@${email.company}`}
                    profile_link={email.profile_link}
                    profile_img={email.profile_image}
                  ></ResultEntry>
                </div>
              ))
            ) : (
              <p>No email data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendingPage;
