import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../Context/AuthContext";
import "./css/SignIn.css"; // Import the custom CSS file
import logo from "../images/logo_with_nodes.png"; // Adjust the logo import based on your logo file's name and path

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  // sign in function
  const handleSubmit = async (e) => {
    e.preventDefault();

    const success = await signIn(email, password);
    if (success === true) {
      navigate("/home");
    } else {
      setError(success);
    }
  };

  return (
    <div className="signin-container">
      {/* Logo at the top */}
      <img src={logo} alt="Logo" className="logo" />
      <div className="signin-card">
        <div>
          <h1 className="signin-title">Sign In</h1>
          <p className="signin-subtitle">
            Don't have an account yet? <Link to="/signup">Sign up.</Link>
          </p>
        </div>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="signin-form">
          <div>
            <label className="signin-label">Email Address</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              className="signin-input"
              placeholder="Enter your email"
              required
            />
          </div>
          <div>
            <label className="signin-label">Password</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              className="signin-input"
              placeholder="Enter your password"
              required
            />
          </div>
          <button type="submit" className="signin-button">
            Sign In
          </button>
        </form>
      </div>
      {/* Privacy Policy link */}
      <div className="privacy-link">
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </div>
    </div>
  );
};

export default Signin;
